import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
export const BookingFlowDataContext = createContext();
function BookingFlowDataContextProvider({ children }) {

  // new home page params start here 
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get("tab"); // e.g. ?tab=round

  const tabMap = {
    one: "0",
    round: "1",
    outstation: "2",
  };

  const defaultTab = tabMap[tabParam] || "0";

  // new home page params end here 

  //make the variable to extract utm params for localStorage  

  const utm_campaign = localStorage.getItem("utm_campaign");
  const utm_medium = localStorage.getItem("utm_medium");
  const utm_source = localStorage.getItem("utm_source");


  const [firstCityId, setFirstCityId] = useState();
  const [secondCityId, setSecondCityId] = useState();
  const [fareChangeOutstation, setFareChangeOutstation] = useState("");

  const [oneWayChecked, setOneWayChecked] = useState(true);
  const [twoWayChecked, setTwoWayChecked] = useState(true);
  const [outstationCheckIns, setOutstationCheckIns] = useState(true);
  const [dailyDUCheckIns, setDailyDUCheckIns] = useState(1);
  const [isInsurance, setIsInsurance] = useState(false);
  const [misMatchCityId, setMisMatchCityId] = useState(false);
  const [isOpenIns, setIsOpenIns] = React.useState(false);
  const [fareEstimateDetail, setFareEstimateDetail] = useState([]);
  const [fareEstimateTwoWay, setFareEstimateTwoWay] = useState([]);
  const [fareEstimateOutstation, setFareEstimateOutstation] = useState([]);
  const [fareEstimateDaily, setFareEstimateDaily] = useState([]);
  const [isErrMsgBooking, setIsErrMsgBooking] = useState("");
  const [showFareEstimate, setShowFareEstimate] = useState(false);
  const [showRoundWayFare, setShowRoundWayFare] = useState(false);
  const [showOutstationFare, setShowOutstationFare] = useState(false);
  const [showBookLoaderRound, setShowBookLoaderRound] = useState(false);
  const [activeKey, setActiveKey] = React.useState(defaultTab);
  const [userInfo, setUserInfo] = useState({});
  const [detailsResponse, setDetailsResponse] = useState({});
  const [cityEtaDetails, setCityEtaDetails] = useState([] || {});
  const [dropCityEtaDetails, setDropCityEtaDetails] = useState([] || {});
  const [eventFareEstimate, setEventFareEstimate] = useState("");
  const [couponData, setCouponData] = useState([] || {});
  const [couponLoading, setCouponLoading] = useState(false);
  const [pkgType, setPkgType] = useState(0);
  const [fareExtraInfo, setFareExtraInfo] = useState("");
  const [cancelFarePopOver, setCancelFarePopOver] = useState("");
  const [getBookingId, setGetBookingId] = useState({
    userBookingId: "",
    userBookingStatus: "",
  });
  const [cookiesUser] = useCookies();
  const [toggleFareEstimate] = useState({
    oneWayFirstToggle: false,
    oneWaySecondToggle: false,
  });
  const [toggleOutstationFare, setToggleOutstationFare] = useState({
    outstationFirstToggle: false,
    outstationSecondToggle: false,
  });
  const [toggleRoundWayFareEstimate, setToggleRoundWayFareEstimate] = useState(
    ""
  );
  const [toggleDailyFareEstimate, setToggleDailyFareEstimate] = useState("");

  const [roundWayToggle, setRoundWayToggle] = useState(false);
  const [detailsData, setDetailsData] = useState({
    booking_id: "",
    created_at: "",
    bookingType: "",
  });

  const [trackPickUpDateTimeArr, setTrackPickUpDateTimeArr] = useState([]);
  const [
    trackPickUpDateTimeArrOneWay,
    setTrackPickUpDateTimeArrOneWay,
  ] = useState([]);
  const [checkedLanguages, setCheckedLanguages] = useState([]);

  const [fareParams, setFareParams] = useState({
    a_v: "2",
    booking_type: "",
    car_type: "",
    city_id: "",
    pickup_address: "",
    drop_address: "",
    pickup_latitude: "",
    pickup_longitude: "",
    drop_latitude: "",
    drop_longitude: "",
    distance: "",
    duration: "",
    format: "json",
    is_insured: +true || +isInsurance,
    num_hours: "",
    pickup_datetime: "",
    // pickup_trip_type: "pick_now",
    service_type: "classic",
    src: "website",
    user_id: cookiesUser.userid,
  });

  const [sendingData, setSendingData] = useState({
    pickup_address: "",
    drop_address: "",
    pickup_latitude: "",
    pickup_longitude: "",
    drop_latitude: "",
    drop_longitude: "",
    service_type: "classic",
    src: "website",
    promo_code: "",
    pickup_datetime: "",
    format: "json",
    booking_type: "",
    city: "",
    user_id: cookiesUser.userid,
    one_way_os_destination: "",
    estimated_trip_minutes: "",
    estimated_trip_hours: "",
    is_insured: oneWayChecked || isInsurance,
    car_type: "",
    app_version: 2,
    my_gate_session_id: "",
    distance: "",
    duration: "",
    utm_campaign,
    utm_medium ,
    utm_source
    
  });
  const [sendingRoundWayData, setSendingRoundWayData] = useState({
    pickup_address: "",
    pickup_latitude: "",
    pickup_longitude: "",
    service_type: "classic",
    src: "website",
    promo_code: "",
    pickup_datetime: "",
    format: "json",
    booking_type: "",
    city: "",
    user_id: cookiesUser.userid,
    one_way_os_destination: "",
    estimated_trip_minutes: "0",
    estimated_trip_hours: "",
    is_insured: twoWayChecked || isInsurance,
    car_type: "",
    app_version: 2,
    my_gate_session_id: "",
    distance: null,
    duration: null,
    utm_campaign,
    utm_medium ,
    utm_source
     
  });
  const [sendingOutstationData, setSendingOutstationData] = useState({
    pickup_address : "",
    pickup_latitude: "",
    pickup_longitude: "",
    drop_address: "",
    drop_latitude: "",
    drop_longitude: "",
    service_type: "classic",
    src: "website",
    promo_code: "",
    pickup_datetime: "",
    format: "json",
    booking_type: "",
    city: "",
    user_id: cookiesUser.userid,
    one_way_os_destination: "",
    estimated_trip_minutes: "0",
    estimated_trip_hours: "",
    is_insured: outstationCheckIns || isInsurance,
    car_type: "",
    app_version: 2,
    my_gate_session_id: "",
    distance: "",
    duration: "",
    utm_campaign,
    utm_medium,
    utm_source
    
  });

  const [sendingDailyBookingData, setSendingDailyBookingData] = useState({
    pickup_address: "",
    pickup_latitude: "",
    pickup_longitude: "",
    drop_address: "",
    drop_latitude: "",
    drop_longitude: "",
    service_type: "daily",
    src: "",
    promo_code: "",
    pickup_datetime: [],
    format: "json",
    booking_type: "",
    city: "",
    user_id: cookiesUser.userid,
    one_way_os_destination: "",
    estimated_trip_minutes: "0",
    estimated_trip_hours: "",
    is_insured: dailyDUCheckIns,
    car_type: "",
    app_version: 2,
    my_gate_session_id: "",
    languages: [],
    order_id: "",
    currency: "INR",
    type: "user",
    purpose: "du_daily",
    amount: "",
    estimated_fare: ""
  });

  const [
    sendingDailyBookingDataOneWay,
    setSendingDailyBookingDataOneWay,
  ] = useState({
    pickup_address: "",
    pickup_latitude: "",
    pickup_longitude: "",
    drop_address: "",
    drop_latitude: "",
    drop_longitude: "",
    service_type: "daily",
    src: "",
    promo_code: "",
    pickup_datetime: [],
    format: "json",
    booking_type: "",
    city: "",
    user_id: cookiesUser.userid,
    one_way_os_destination: "",
    estimated_trip_minutes: "0",
    estimated_trip_hours: "",
    is_insured: dailyDUCheckIns,
    car_type: "",
    app_version: 2,
    my_gate_session_id: "",
    languages: [],
    order_id: "",
    currency: "INR",
    type: "user",
    purpose: "du_daily",
    amount: "",
    estimated_fare: "",
  });
  const [insuranceUserId, setInsuranceUserId] = useState("");
  const [dateTimeAllWay, setDateTimeAllWay] = useState("");
  const [onlinePaymentData, setOnlinePaymentData] = useState({
    currency: "INR",
    src: "website",
    type: "user",
    purpose: "booking",
  });

  const [showOnlinePaymentPopUp, setShowOnlinePaymentPopUp] = useState(false);

  //  Eta for one Way
  const [showETA, setShowETA] = useState(null);
  const [etaOneWayTime, setEtaOneWayTime] = useState(0);
  const [showWhen, setShowWhen] = useState([{ label: "Now", id: "#F0F8FF" }]);
  const [oneWayETATimeString, setOneWayETATimeString] = useState("");

  //  ETA for Round Way
  const [showRoundETA, setShowRoundETA] = useState(null);
  const [etaRoundWayTime, setEtaRoundWayTime] = useState(0);
  const [showRoundWhen, setShowRoundWhen] = useState([
    { label: "Now", id: "#F0F8FF" },
  ]);
  const [roundWayETATimeString, setRoundWayETATimeString] = useState("");
  const [trackDriverScreen, setTrackDriverScreen] = useState("myOrders");
  const [defaultFareParams] = useState({
    a_v: "2",
    booking_type: "",
    car_type: "",
    city_id: "",
    pickup_address: "",
    drop_address: "",
    pickup_latitude: "",
    pickup_longitude: "",
    drop_latitude: "",
    drop_longitude: "",
    distance: "",
    duration: "",
    format: "json",
    is_insured: +true || +isInsurance,
    num_hours: "",
    pickup_datetime: "",
    // pickup_trip_type: "pick_now",
    service_type: "classic",
    src: "website",
    user_id: cookiesUser.userid,
  });
  return (
    <BookingFlowDataContext.Provider
      value={{
        firstCityId,
        setFirstCityId,
        misMatchCityId,
        setMisMatchCityId,
        secondCityId,
        setSecondCityId,
        sendingData,
        setSendingData,
        sendingRoundWayData,
        setSendingRoundWayData,
        fareEstimateDetail,
        setFareEstimateDetail,
        toggleFareEstimate,
        toggleRoundWayFareEstimate,
        setToggleRoundWayFareEstimate,
        setRoundWayToggle,
        roundWayToggle,
        fareEstimateTwoWay,
        setFareEstimateTwoWay,
        oneWayChecked,
        setOneWayChecked,
        twoWayChecked,
        setTwoWayChecked,
        isInsurance,
        setIsInsurance,
        isOpenIns,
        setIsOpenIns,
        isErrMsgBooking,
        setIsErrMsgBooking,
        detailsData,
        setDetailsData,
        getBookingId,
        setGetBookingId,
        activeKey,
        setActiveKey,
        showFareEstimate,
        setShowFareEstimate,
        showRoundWayFare,
        setShowRoundWayFare,
        userInfo,
        setUserInfo,
        showBookLoaderRound,
        setShowBookLoaderRound,
        insuranceUserId,
        setInsuranceUserId,
        dateTimeAllWay,
        setDateTimeAllWay,
        detailsResponse,
        setDetailsResponse,
        onlinePaymentData,
        setOnlinePaymentData,
        showOnlinePaymentPopUp,
        setShowOnlinePaymentPopUp,
        // Eta for one Way
        showETA,
        setShowETA,
        etaOneWayTime,
        setEtaOneWayTime,
        showWhen,
        setShowWhen,
        oneWayETATimeString,
        setOneWayETATimeString,
        //  ETA for round way
        showRoundETA,
        setShowRoundETA,
        etaRoundWayTime,
        setEtaRoundWayTime,
        showRoundWhen,
        setShowRoundWhen,
        roundWayETATimeString,
        setRoundWayETATimeString,
        cityEtaDetails,
        setCityEtaDetails,
        dropCityEtaDetails,
        setDropCityEtaDetails,
        eventFareEstimate,
        setEventFareEstimate,
        trackDriverScreen,
        setTrackDriverScreen,
        sendingOutstationData,
        setSendingOutstationData,
        outstationCheckIns,
        setOutstationCheckIns,
        showOutstationFare,
        setShowOutstationFare,
        toggleOutstationFare,
        setToggleOutstationFare,
        fareEstimateOutstation,
        setFareEstimateOutstation,
        couponData,
        setCouponData,
        couponLoading,
        setCouponLoading,
        fareParams,
        setFareParams,
        // dailydu
        sendingDailyBookingData,
        setSendingDailyBookingData,
        dailyDUCheckIns,
        setDailyDUCheckIns,
        fareEstimateDaily,
        setFareEstimateDaily,
        pkgType,
        setPkgType,
        toggleDailyFareEstimate,
        setToggleDailyFareEstimate,
        trackPickUpDateTimeArr,
        setTrackPickUpDateTimeArr,
        checkedLanguages,
        setCheckedLanguages,
        sendingDailyBookingDataOneWay,
        setSendingDailyBookingDataOneWay,
        trackPickUpDateTimeArrOneWay,
        setTrackPickUpDateTimeArrOneWay,
        fareExtraInfo,
        setFareExtraInfo,
        cancelFarePopOver,
        setCancelFarePopOver,
        fareChangeOutstation,
        setFareChangeOutstation,
        defaultFareParams,
      }}
    >
      {children}
    </BookingFlowDataContext.Provider>
  );
}

export default BookingFlowDataContextProvider;
